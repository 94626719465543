<template>
  <div class="recover-account-first-step recover">
    <p class="recover__title">{{ $t('account-has-been-deleted') }}</p>
    <ui-button @click="$emit('continue')">
      {{ $t('restore') }}
    </ui-button>
    <ui-button @click="$emit('back')" color="outline">
      {{ $t('cancel-1') }}
    </ui-button>
  </div>
</template>


<script>
export default {
  name: "RecoverAccountFirstStep",
  components: {
    UiButton: () => import("@/components/ui/UiButton"),
  },
}
</script>

<style lang="scss" scoped>
.recover {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  &__title {
    text-align: center;
    color: #1B1A1F;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
</style>
